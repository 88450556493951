.raceItem {
    box-sizing: border-box;
    display: block;
    clear: both;
}

.raceDetails {
    float: left;
    box-sizing: border-box;
    display: block;
}

.timeHolder {
    float: right;
    padding-top: 13px;
    display: block;
}

.racetrack_name {
    font-size: 18px;
    font-weight: bold;
}

.racenumber {
    font-size: 12px;
    padding-left: 5px;
}


.bd-example-row .row>.col, .bd-example-row .row>[class^=col-] {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
}
.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.cell {
    min-height: 100px;
    border: 2px solid green;
    margin: 1rem;
}


.raceSubDetails {
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #999999;
    margin-right: 10px;
}

