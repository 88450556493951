.true {
    height: 16px;
    width: 16px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.false {
    height: 16px;
    width: 16px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.conditionRow {
    font-size: 18px;
    vertical-align: middle;
}